import React from 'react';
import { PhoneNumberContainer, PhoneSvgIcon } from './PhoneNumber.styles';
import { Text } from '@/components/typography';

export interface PhoneNumberProps {
  phoneNumberList: [PhoneItem];
  /** Wether or not shoud filter out only WhatsApp numbers. Not confuse with the type value.*/
  displayType: 'whatsapp' | 'landline';
}

function formatPhoneNumber(phoneType: PhoneItemType['value'], areaCode: string | number, phoneNumber: string | number) {
  let formattedPhoneNumber = `55${areaCode}${phoneNumber}`.replace(/\D+/g, '');
  if (phoneType.toLowerCase() === 'whatsapp') {
    return `https://wa.me/${formattedPhoneNumber}?source=site`;
  } else {
    return `tel:+${formattedPhoneNumber}`;
  }
}

const PhoneNumber = ({ displayType = 'landline', phoneNumberList, ...props }: PhoneNumberProps) => {
  return (
    <PhoneNumberContainer {...props}>
      {phoneNumberList
        .filter((item) => (displayType !== 'whatsapp' ? true : item.type.value.toLowerCase() === 'whatsapp'))
        .map((item: PhoneItem) => (
          <React.Fragment key={item.phone}>
            <PhoneSvgIcon value={item.type.value} />
            <div>
              <a href={formatPhoneNumber(item.type.value, item.areaCode, item.phone)} target="_blank">
                ({item.areaCode}) {item.phone}
              </a>
            </div>
            <Text size="sm" weight="light" color="neutral_600">
              {item.label}
            </Text>
          </React.Fragment>
        ))}
    </PhoneNumberContainer>
  );
};

export default PhoneNumber;
