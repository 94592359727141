import styled, { css } from 'styled-components';

export const Element = styled.div`
  ${({ theme }) => {
    return css`
      &:not(:first-of-type) {
        padding-top: ${theme.spacing.base};
      }
    `;
  }};
`;
