import React, { ReactNode } from 'react';
import Link from 'next/link';
import { Element, PhoneDropdownBody, PhoneDropdownFooter, PhoneDropdownHeader } from './PhoneListDropdown.styles';
import { Text, Title } from '@/components/typography';
import OfficePhoneGroup from '@/components/navigation/ContactMenu/OfficePhoneGroup/OfficePhoneGroup';
import { useAppContext } from '@/context/AppContext';

export interface PhoneListDropdownProps {
  displayType?: 'whatsapp' | 'landline';
  className?: string;
  children?: ReactNode;
}

const DropdownOfficePhone = ({ displayType = 'landline', children, ...props }: PhoneListDropdownProps) => {
  const { navigation } = useAppContext();
  const { phones } = navigation;

  if (!phones.length) {
    return null;
  }

  return (
    <Element {...props}>
      <PhoneDropdownHeader>
        <Title as="h5" size="md" textCase="capitalize">
          Atendimento {displayType !== 'whatsapp' ? `Kangaroo` : `por WhatsApp`}
        </Title>
        <Text>Encontre o escritório Kangaroo mais próximo de você</Text>
      </PhoneDropdownHeader>
      <PhoneDropdownBody>
        {phones.map((office: OfficeObject) => (
          <React.Fragment key={office.id}>
            <OfficePhoneGroup displayType={displayType} office={office} />
          </React.Fragment>
        ))}
      </PhoneDropdownBody>
      <PhoneDropdownFooter>
        <Text as="span">Ou, utilize o formulário:</Text>{' '}
        <Text as="strong" weight="bold">
          <Link href="/contato">Fale Conosco</Link>
        </Text>
      </PhoneDropdownFooter>
    </Element>
  );
};

export default DropdownOfficePhone;
