import styled, { css } from 'styled-components';
import { ElevationProps } from './Elevation';

export const Element = styled.div.withConfig({
  shouldForwardProp: (prop) => !['radius', 'shadow', 'spacing'].includes(prop),
})<ElevationProps>`
  ${({ theme, radius, shadow, spacing }) => {
    return css`
      background-color: ${theme.color.white};
      box-shadow: ${(shadow && theme.box_shadow[shadow]) || theme.box_shadow.default};
      padding: ${(spacing && theme.spacing[spacing]) || theme.spacing.xs};
      border-radius: ${(radius && theme.radius[radius]) || theme.radius.xs};
    `;
  }}
`;
