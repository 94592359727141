import React from 'react';
import { Text } from '@/components/typography';
import PhoneNumber from '@/components/navigation/ContactMenu/PhoneNumber/PhoneNumber';
import { Element } from './OfficePhoneGroup.styles';

export interface OfficePhoneGroupProps {
  office: OfficeObject;
  displayType: 'whatsapp' | 'landline';
  className?: string;
}

const OfficePhoneGroup = ({ displayType, office, ...props }: OfficePhoneGroupProps): JSX.Element => {
  return (
    <Element {...props}>
      <Text size="sm">Escritório</Text>
      <Text size="base" weight="bold" textCase="uppercase">
        {office.region}
      </Text>
      <PhoneNumber phoneNumberList={office.phoneNumberList} displayType={displayType} />
    </Element>
  );
};

export default OfficePhoneGroup;
