import React, { ReactElement, ReactNode, useEffect, useRef } from 'react';
import { Element } from '@/components/dropdown/Dropdown.styles';
import { ElevationProps } from '@/components/elevation/Elevation';

export type RenderProps = {
  ref: React.MutableRefObject<HTMLAnchorElement | null>;
  role?: string;
  'aria-expanded': boolean;
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
};
export interface DropdownProps extends ElevationProps {
  trigger?: ReactElement;
  render?: (props: RenderProps) => void;
  top?: string | number;
  bottom?: string | number;
  className?: string;
  children: ReactNode;
}

const ARIA_EXPANDED_ATTR = 'aria-expanded';
const ARIA_HIDDEN_ATTR = 'aria-hidden';

const Dropdown = ({ trigger, render, children, ...props }: DropdownProps) => {
  const triggerRef = useRef<HTMLAnchorElement | null>(null);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const toggle = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    const triggerEl = triggerRef.current;
    const dropdownEl = dropdownRef.current;

    let isExpanded = triggerEl?.getAttribute(ARIA_EXPANDED_ATTR) === 'true';
    let isHidden = dropdownEl?.getAttribute(ARIA_HIDDEN_ATTR) === 'true';

    triggerEl?.setAttribute(ARIA_EXPANDED_ATTR, `${!isExpanded}`);
    dropdownEl?.setAttribute(ARIA_HIDDEN_ATTR, `${!isHidden}`);
  };

  useEffect(() => {
    function handleClick(e: MouseEvent) {
      e.stopPropagation();
      const currentTarget = e.target as HTMLButtonElement;
      if (currentTarget?.getAttribute(ARIA_EXPANDED_ATTR) === 'true') {
        return;
      }

      const triggerEl = triggerRef.current;
      const dropdownEl = dropdownRef.current;

      let isExpanded = triggerEl?.getAttribute(ARIA_EXPANDED_ATTR) === 'true';
      let isHidden = dropdownEl?.getAttribute(ARIA_HIDDEN_ATTR) === 'true';

      if (!isExpanded) {
        return;
      }

      const d = dropdownRef.current?.getBoundingClientRect();
      if (!d) return;

      if (e.clientX < d.left || e.clientX > d.right || e.clientY < d.top || e.clientY > d.bottom) {
        triggerEl?.setAttribute(ARIA_EXPANDED_ATTR, `${!isExpanded}`);
        dropdownEl?.setAttribute(ARIA_HIDDEN_ATTR, `${!isHidden}`);
      }
    }
    function handleEscape(e: KeyboardEvent) {
      if (e.key !== 'Escape') return;

      const triggerEl = triggerRef.current;
      const dropdownEl = dropdownRef.current;

      let isExpanded = triggerEl?.getAttribute(ARIA_EXPANDED_ATTR) === 'true';
      let isHidden = dropdownEl?.getAttribute(ARIA_HIDDEN_ATTR) === 'true';

      if (!isExpanded) {
        return;
      }

      triggerEl?.setAttribute(ARIA_EXPANDED_ATTR, `${!isExpanded}`);
      dropdownEl?.setAttribute(ARIA_HIDDEN_ATTR, `${!isHidden}`);
    }

    document.body.addEventListener('keyup', handleEscape, false);
    document.body.addEventListener('click', handleClick, false);

    // unmount
    return () => {
      document.body.removeEventListener('keyup', handleEscape, false);
      document.body.removeEventListener('click', handleClick, false);
    };
  }, []);

  return (
    <>
      {trigger
        ? React.cloneElement(trigger, {
            onClick: toggle,
            // role: 'button',
            'aria-expanded': 'false',
            ref: triggerRef,
          })
        : null}
      {render
        ? render({
            onClick: (e) => {
              toggle(e);
            },
            // role: 'button',
            'aria-expanded': false,
            ref: triggerRef,
          })
        : null}
      <Element aria-hidden="true" ref={dropdownRef} {...props}>
        {children}
      </Element>
    </>
  );
};

export default Dropdown;
