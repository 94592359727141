import styled, { css } from 'styled-components';
import { ElevationProps } from '@/components/elevation/Elevation';
import { Element as ElevationElement } from '@/components/elevation/Elevation.styles';
import { transitionShowIn } from '@/styles/utilities';
import { DropdownProps } from '@/components/dropdown/Dropdown';

export const Element = styled(ElevationElement).withConfig({
  shouldForwardProp: (prop) => !['top', 'bottom'].includes(prop),
})<Pick<DropdownProps, 'top' | 'bottom'> & ElevationProps>`
  ${({ theme, top, bottom }) => {
    return css`
      width: auto;
      position: absolute;
      top: ${(top !== undefined && top) || '100%'};
      left: auto;
      bottom: ${(bottom !== undefined && bottom) || 'auto'};
      right: 0;

      height: 0;
      opacity: 0;
      overflow: hidden;
      visibility: hidden;

      z-index: 1100;

      // Transition effect
      ${transitionShowIn};

      // Via selector
      &[aria-hidden='false'] {
        height: auto;
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
      }
    `;
  }};
`;
