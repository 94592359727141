import styled, { css } from 'styled-components';
import { scrollingVisible } from '@/styles/utilities';

export const Element = styled.div`
  ${({ theme, ...props }) => {
    return css`
      display: flex;
      flex-direction: column;
      gap: ${theme.spacing.md};

      max-width: calc(100vw - 1rem);
      min-width: calc(100vw - 4rem);
      max-height: calc(100vh - 15rem);

      @media screen and (min-width: ${theme.breakpoint.sm}) {
        width: 25rem;
        min-width: 20rem;
        max-width: 27rem;
        max-height: 80vh;
      }
    `;
  }};
`;

export const PhoneDropdownHeader = styled.div`
  //
`;

export const PhoneDropdownBody = styled.div`
  height: auto;
  overflow: scroll;
  flex-grow: 1;

  ${scrollingVisible};
`;
export const PhoneDropdownFooter = styled.div`
  //
`;
